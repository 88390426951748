<template>
  <v-form>
    <v-container>
      <v-row class="d-flex align-center">
        <h1>
          <base-arrow-back-icon @click="$router.go(-1)">{{
            rightArrowIcon
          }}</base-arrow-back-icon>
          {{ this.$t("students.info.edit") }}
        </h1>
      </v-row>
    </v-container>
    <v-container>
      <v-row class="first-row">
        <v-col cols="12" md="6">
          <v-col cols="12" md="4">
            <v-subheader>{{ this.$t("students.info.avatar") }}</v-subheader>
            <v-file-input
              accept="image/*"
              :prepend-icon="cameraIcon"
              v-model="avatar"
            ></v-file-input>
          </v-col>
        </v-col>

        <v-col cols="12" md="6"></v-col>
      </v-row>

      <v-row class="second-row">
        <v-col cols="12" md="4">
          <v-subheader>{{ this.$t("students.info.First") }}</v-subheader>
          <v-text-field
            v-model="firstName"
            :rules="requiredRule"
            solo
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-subheader>{{ this.$t("students.info.Last") }}</v-subheader>
          <v-text-field
            v-model="lastName"
            :rules="requiredRule"
            solo
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-subheader>{{ this.$t("students.info.email") }}</v-subheader>
          <v-text-field
            v-model="email"
            :rules="requiredRule"
            solo
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="third-row">
        <v-col cols="12" md="6">
          <v-subheader>{{ this.$t("students.info.Phone") }}</v-subheader>
          <v-text-field
            v-model="phone"
            :rules="requiredRule"
            solo
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-subheader>{{
            this.$t("students.info.IdentityNumber")
          }}</v-subheader>
          <v-text-field
            v-model="identityNumber"
            :rules="requiredRule"
            solo
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="fourth-row">
        <v-col cols="12" md="6">
          <v-subheader>{{ this.$t("students.info.Country") }}</v-subheader>
          <v-select
            :items="items"
            :rules="requiredRule"
            v-model="country"
            solo
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-subheader>{{ this.$t("students.info.BirthDate") }}</v-subheader>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="birthDate"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="birthDate"
                label="Birthdate"
                v-bind="attrs"
                :rules="requiredRule"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="birthDate"
              no-title
              scrollable
              color="#69d1b6"
            >
              <v-spacer></v-spacer>
              <v-btn text @click="menu = false">Cancel</v-btn>
              <v-btn color="#69d1b6" @click="$refs.menu.save(birthDate)" dark
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row class="last-row">
        <v-col cols="12" md="6">
          <base-button @click="save" :options="{ block: true }">{{
            this.$t("students.info.Save")
          }}</base-button>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import BaseArrowBackIcon from "../../../core/Base/Arrows/BaseArrowBackIcon";
import BaseButton from "../../../core/Base/Buttons/BaseButton";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "StudentEdit",
  props: [
    "avatar",
    "firstName",
    "lastName",
    "email",
    "createdAt",
    "phone",
    "identityNumber",
    "country",
    "birthDate",
  ],
  components: {
    BaseArrowBackIcon,
    BaseButton,
  },
  data() {
    return {
      requiredRule: [(v) => !!v || this.$t("required.field")],
      form: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        country: "",
        identityNumber: "",
        birthDate: "",
        avatar: [],
      },
      items: ["Syria", "Lebanon", "USA", "Turkey"],
    };
  },
  methods: {
    fetchStudentInfo() {
      this.getStudentInfo(this.$route.params.id).then(() => {
        this.form = this.getStudInfo;
      });
    },
    save() {
      this.editStudentPage({
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.phone,
        email: this.email,
        country: this.country,
        identityNumber: this.identityNumber,
        birthDate: this.birthDate,
        avatar: this.avatar,
        id: this.$route.params.id,
      })
        .then(() => {
          this.fetchStudentInfo();
          setTimeout(() => {
            this.$router.push({ name: "students" });
          }, 2000);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    deleteStud() {
      this.deleteStudent(this.$route.params.id);
    },
    ...mapActions("DashBoard/Students", ["getStudentInfo", "editStudentPage"]),
  },
  computed: {
    ...mapGetters("DashBoard/Students", ["getStudInfo"]),
  },
  created() {
    console.log(this.$route.params.id);
    this.fetchStudentInfo();
  },
};
</script>

<style scoped>
</style>
